<template>
  <ul class="user-list">
    <li v-for="(item, index) in nativeData" :key="index">
      <router-link :to="{ name: 'Personal', query: { uid: item.id } }">
        <img :src="item.userImg" class="avatar" alt="" />
        <div class="user-name">{{ item.nickName }}</div>
        <div class="user-sign">{{ item.personalSignature }}</div>
      </router-link>

      <button
        :class="!item.isMyGuan ? 'focus' : 'cancel-focus'"
        @click="onClick(!item.isMyGuan, index, item.id)"
      >
        <img
          v-if="!item.isMyGuan"
          src="@/assets/img/personal/icon37.png"
          alt=""
        />
        {{ !item.isMyGuan ? "关注" : "取消关注" }}
      </button>
    </li>
  </ul>
  <empty text="" :showLive="false" v-if="follow.empty"></empty>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import dateFormat from "@/utils/dateFormat";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import qs from "qs";
import Empty from "../common/Empty.vue";
export default defineComponent({
  components: { Empty },
  name: "Follow",
  props: {
    follow: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props) {
    const nativeData = computed(() => {
      return props.follow.data.slice(0);
    });

    const router = useRouter();
    function followWithInterest(userid: string, index: number) {
      const data = qs.stringify({
        userid,
      });
      axios
        .post("/M/BBSServer/Follow", data, {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          nativeData.value[index].isMyGuan = true;
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
         console.log(err)
        });
    }
    function unFollowWithInterest(userid: string, index: number) {
      const data = qs.stringify({
        userid,
      });
      axios
        .post("/M/BBSServer/CancelFollow", data, {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          if (res.data.success) {
            nativeData.value[index].isMyGuan = false;
            Toast({
              type: "success",
              title: res.data.msg,
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
         console.log(err)
        });
    }
    function onClick(isFocus: boolean, index: number, id: string) {
      if (isFocus) {
        followWithInterest(id, index);
      } else {
        unFollowWithInterest(id, index);
      }
    }

    return {
      dateFormat,
      onClick,
      nativeData,
    };
  },
});
</script>

<style lang="scss" scoped>
.user-list {
  li {
    height: 92px;
    padding-left: 75px;
    padding-top: 20px;
    border-bottom: 1px solid #eeeeee;
    box-sizing: border-box;
    position: relative;
    .avatar {
      position: absolute;
      left: 10px;
      top: 20px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    .user-name {
      width: 200px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #444444;
      font-size: 15px;
    }

    .user-sign {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #999999;
      font-size: 14px;
      margin-top: 10px;
    }

    .cancel-focus {
      background-color: #fff;
      border: none;
      outline: none;
      position: absolute;
      right: 10px;
      top: 20px;
      width: 75px;
      padding: 0;
      height: 27.5px;
      border-radius: 14px;
      border: solid 1px #ccc;
      line-height: 27.5px;
      text-align: center;
      color: #ccc;
      font-size: 12px;
    }

    .focus {
      background-color: #fff;
      border: none;
      outline: none;
      position: absolute;
      right: 10px;
      top: 20px;
      width: 72.5px;
      height: 27.5px;
      border-radius: 14px;

      border: solid 1px #df0024;
      line-height: 27.5px;
      text-align: center;
      color: #df0024;
      font-size: 12px;
      display: flex;
      border: 1px solid #df0024;
      align-items: center;
      justify-content: center;
      img {
        width: 10.987px;
        height: 10.987px;
        margin-right: 3px;
      }
    }
  }
}
</style>
